import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthState } from '../auth/store/auth.state';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly authService: AuthService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.checkAuth();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuth();
    }

    checkAuth(): Observable<boolean> {
        return this.authService.profileLoaded$.pipe(
            filter(loaded => loaded),
            switchMap(() => this.store.select(AuthState.isAuth)),
            map(login => {
                if (!login) {
                    this.router.navigate([ '/login' ]).then();
                }

                return login;
            })
        );
    }
}
