import { TableFilterItem } from '@shared/fleet-table/interfaces/data-query-service.interface';
import { PageEvent } from '@angular/material/paginator/paginator';
import { Sort } from '@angular/material/sort';
import { InterfaceGetCarEvents } from '@app/pages/cars/store/interfaces/get-car-events.interface';
import { InterfaceSetMileage } from '@app/pages/cars/store/interfaces/set-mileage.interface';
import { Vehicle } from '@app/pages/cars/store/interfaces/vehicle.interface';
import { CarStatus } from './enum/car-status.enum';

export class CarsSearchAction {
    public static readonly type = '[Cars] search cars';
    constructor(public payload: string) { }
}

export class CarsGetPageAction {
    public static readonly type = '[Cars] get page';
    constructor(public payload: number) { }
}

export class CarsGetVehicles {
    public static readonly type = '[Cars] get vehicles';
    constructor(public page: number,
        public currentUserId?: string
    ) { }
}

export class CarsGetVehicleMainInfo {
    public static readonly type = '[Cars] get vehicle main info';
    constructor(public payload: string) { }
}

export class CarsGetStatusRefAction {
    public static readonly type = '[Cars] get statuses';
}

export class CarsPaginatorEventAction {
    public static readonly type = '[Cars] event paginator';
    constructor(public payload: PageEvent) { }
}

export class CarsSortAction {
    public static readonly type = '[Cars] sort data';
    constructor(public payload: Sort) { }
}

export class CarsFiltersAction {
    public static readonly type = '[Cars] set filters';
    constructor(public payload: TableFilterItem[]) { }
}

export class CarsColumnsAction {
    public static readonly type = '[Cars] set filters';
    constructor(public payload: string[]) { }
}

export class CarsLoadVehicleStatusCounts {
    public static type = '[Cars] Load Vehicle Status Counts';
}

export class CarsGetCarEvents {
    static readonly type = '[Cars] Get Car Events';

    constructor(public payLoad: InterfaceGetCarEvents) {
    }
}

export class SetMileage {
    static readonly type = '[Cars] Set Mileage';

    constructor(public payLoad: InterfaceSetMileage) {
    }
}

export class CarsGetSimIdByVinCar {
    static readonly type = '[Cars] Get Sim-Id';

    constructor(public payLoad: string) {
    }
}

export class CarsLinkVehicleToPark {
    static readonly type = '[Cars] Link Vehicle To Park';

    constructor(public vehicle: Vehicle,
        public groupId: string,
        public isSelected: boolean
    ) { }
}
export class CarsToggleStatusFilter {
    static readonly type = '[Cars] Toggle Status Filter';
    constructor(public status: CarStatus) { }
}

export class CarsSelectStatusFilter {
    static readonly type = '[Cars] Select Status Filter';
    constructor(public status: CarStatus) { }
}

export class CarsDeselectStatusFilter {
    static readonly type = '[Cars] Deselect Status Filter';
    constructor(public status: CarStatus) { }
}

export class CarsClearStatusFilter {
    static readonly type = '[Cars] Clear Status Filter';
}

export class CarsChangeShowByParks {
    static readonly type = '[Cars] Change Show By Parks';
    constructor(
        public showByParks: boolean
    ) { }
}

export class CarsSelectPark {
    static readonly type = '[Cars] Select Park';
    constructor(
        public parkId: string
    ) { }
}

export class CarsDeselectPark {
    static readonly type = '[Cars] Deselect Park';
    constructor(
        public parkId: string
    ) { }
}

export class CarsToggleParkSelected {
    static readonly type = '[Cars] Toggle Park Selected';
    constructor(
        public parkId: string
    ) { }
}

export class CarsSetParkRemoveAllowed {
    static readonly type = '[Cars] Set Park Remove Allowed';
    constructor(
        public parkRemoveAllowed: boolean
    ) { }
}

export class CarsToggleParkRemoveAllowed {
    static readonly type = '[Cars] Toggle Park Remove Allowed';
}

export class CarsRemovePark {
    static readonly type = '[Cars] Remove Park';
    constructor(
        public parkId: string
    ) { }
}

export class CarsExportList {
    static readonly type = '[Cars] Export List';
}
