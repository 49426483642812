import { Component, OnInit } from '@angular/core';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';


@Component({
    selector: 'hml-mobility-route-handler',
    templateUrl: './route-handler.component.html',
    styleUrls: [ './route-handler.component.scss' ],
    animations: [ fadeInOnEnterAnimation({ duration: 300 }),
        fadeOutOnLeaveAnimation({ duration: 300 }) ],
})
export class RouteHandlerComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
