import { CarStatus } from "../enum/car-status.enum";

export const CAR_STATUS_NAMES: Record<CarStatus, string> = {
    [CarStatus.Discharged]: 'Разряжен АКБ',
    [CarStatus.NoSignal]: 'Не в сети',
    [CarStatus.OnTheRoad]: 'В движении',
    [CarStatus.Parking]: 'На парковке',
    [CarStatus.Towing]: 'Эвакуация',
    [CarStatus.Warning]: 'Неизвестно',
    [CarStatus.OnRent]: 'В аренде',
    [CarStatus.RentCompleted]: 'Аренда завершена'
}

export function getCarStatusName(status: CarStatus): string {
    return CAR_STATUS_NAMES[status];
}
