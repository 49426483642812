/**
 * @example
 * {
 *   fieldName: 'registrationNumber',
 *   fieldType: 'string',
 *   filterType: 'like',
 *   value: value || '', // input value here
 * }
 *
 */
export const registrationNumberLike = (value) => ({
    value: value ?? '',
    fieldName: 'registrationNumber',
    fieldType: 'string',
    filterType: 'like',
});

/**
 * @example
 * {
 *   fieldName: 'email',
 *   fieldType: 'string',
 *   filterType: 'equals',
 *   value, // input value here
 * }
 *
 */
export const emailEquals = (value) => ({
    value,
    fieldName: 'email',
    fieldType: 'string',
    filterType: 'equals',
});
