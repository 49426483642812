import { HttpParams, HttpResponse } from '@angular/common/http';


/** класс с helper методами */
export class HelperUtil {
    /** формат даты без времени */
    static dateFormat = 'dd.MM.yyyy';
    /** формат даты со временем без секунд */
    static dateTimeNoSecondsFormat = 'dd.MM.yyyy HH:mm';
    /** формат даты со временем */
    static dateTimeFormat = 'dd.MM.yyyy HH:mm:ss';
    /** формат даты со временем для либы moment */
    static momentDateTimeFormat = 'DD.MM.YYYY, HH:mm:ss';
    /** размеры страниц пейджера */
    static pageSizeOptions = [ 15, 30, 50, 100 ];
    /** паттерн пароля */
    static passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{7,50}$/;

    /** конструктор http параметров */
    static buildHttpParams(filter: object): HttpParams {
        let params = new HttpParams();

        Object.keys(filter).forEach((key: string) => {
            const value = typeof filter[key] === 'string' ? filter[key].trim() : filter[key];
            if (value || value === false) {
                if (Array.isArray(value)) {
                    value.forEach((data) => (params = params.append(`${key}[]`, data)));
                } else {
                    params = params.append(`${key}`, value);
                }
            }
        });
        return params;
    }

    /** невалидный примитив */
    static isInvalidPrimitive(value): boolean {
        return value === null || value === undefined || value === '' || ((typeof value === 'string' && value.trim() === ''));
    }

    /** валидный примитив */
    static isValidPrimitive(value): boolean {
        return !HelperUtil.isInvalidPrimitive(value);
    }

    /** невалидное значение */
    static isInvalidValue(value): boolean {
        return HelperUtil.isInvalidPrimitive(value) || (Array.isArray(value) && !value.length);
    }

    /** валидное значение */
    static isValidPrimitives(...value): boolean {
        return !value.some(HelperUtil.isInvalidPrimitive);
    }
    /**
   * вернет слово в правильном склонении в зависимости от числа
   * 1 — яблоко
   * 2 — яблока
   * 5 — яблок
   */
    static getNoun(number: number, one: string, two: string, five: string): string {
        //принципиально возвращать undefined / для bind-once
        if (HelperUtil.isInvalidPrimitive(number)) { return <null | undefined>number; }
        number = Math.abs(number);
        number %= 100;
        if (number >= 5 && number <= 20) {
            return five;
        }
        number %= 10;
        if (number === 1) {
            return one;
        }
        if (number >= 2 && number <= 4) {
            return two;
        }
        return five;
    }

    /**
     *
     * @example
     * ```
     * HelperUtil.convertSecondsToShortReadableDuration(1234134134, false);
     * // '1 день 04:55:00'
     *
     * HelperUtil.convertSecondsToShortReadableDuration(1234134134, true);
     * // '1 день 04:55'
     * ```
     *
     * @param seconds
     * @param noSeconds
     * @returns
     */
    static convertSecondsToHumanReadableDuration(
        seconds: number,
        noSeconds = false
    ): string {
        const d = Math.floor(seconds / 86400);
        const h = Math.floor(seconds % 86400 / 3600);
        const m = Math.floor(seconds % 86400 % 3600 / 60);
        const s = Math.floor(seconds % 86400 % 3600 % 60);

        const addZero = (n: number) => n < 10 ? `0${n}` : n.toString();

        const dDisplay = d > 0 ? `${d} ${HelperUtil.getNoun(d, 'день', 'дня', 'дней')}` : '';
        const hDisplay = addZero(h);
        const mDisplay = addZero(m);
        const sDisplay = addZero(s);

        const arTime = [ hDisplay, mDisplay ];

        if (!noSeconds) {
            arTime.push(sDisplay);
        }

        return [ dDisplay, arTime.join(':') ].join(' ').trim();
    }

    /** положительное целое число */
    static hasOnlyDigits(value: string): boolean {
        return /^-?\d+$/.test(value);
    }

    /** любое число */
    static isNumber(val: string | number): boolean {
        if (this.isInvalidPrimitive(val)) { return false; }
        const numberReSnippet = '(?:NaN|-?(?:(?:\\d+|\\d*\\.\\d+)(?:[E|e][+|-]?\\d+)?|Infinity))';
        const matchOnlyNumberRe = new RegExp('^(' + numberReSnippet + ')$');
        return matchOnlyNumberRe.test(val.toString());
    }

    /** объекты равны */
    static objectEquals(x, y): boolean {
        if (x === null || x === undefined || y === null || y === undefined) {
            return x === y;
        }
        // after this just checking type of one would be enough
        if (x.constructor !== y.constructor) { return false; }
        // if they are functions, they should exactly refer to same one (because of closures)
        if (x instanceof Function) { return x === y; }
        // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
        if (x instanceof RegExp) { return x === y; }
        if (x === y || x.valueOf() === y.valueOf()) { return true; }
        if (Array.isArray(x) && x.length !== y.length) { return false; }

        // if they are dates, they must had equal valueOf
        if (x instanceof Date) { return false; }

        // if they are strictly equal, they both need to be object at least
        if (!(x instanceof Object)) { return false; }
        if (!(y instanceof Object)) { return false; }

        // recursive object equality check
        const p = Object.keys(x);
        return Object.keys(y).every((i) => p.indexOf(i) !== -1)
            && p.every((i) => this.objectEquals(x[i], y[i]));
    }

    // equal [1, 2, 0] and [0, 2, 1]
    static arrayEqualsEvenShuffle(x, y): boolean {
        return this.objectEquals(this.isFilledArray(x) ? x.sort() : x, this.isFilledArray(y) ? y.sort() : y);
    }

    /** НЕ пустой массив */
    static isFilledArray(arr: any[]): boolean {
        return !!arr && Array.isArray(arr) && !!arr.length;
    }

    //{id:null}+{id:4,name:'ret'} -> {id:4}
    static extendObjectByKnownProperties<COMMON, T extends COMMON, Y extends COMMON>(base: T, second: Y): T {
        const ret: T = <T>{};
        for (const key in base) {
            if (base.hasOwnProperty(key) && base[key] !== undefined && key in second) {
                ret[key] = second[<any>key];
            } else if (base[key] !== undefined) {
                ret[key] = base[<any>key];
            }
        }
        return ret;
    }

    static splitArrayOnChunks<T>(array: T[], chunkSize: number): T[][] {
        if (!array || !HelperUtil.isFilledArray(array)) { return; }
        const subarray = []; //массив в который будет выведен результат.
        for (let i = 0; i < Math.ceil(array.length / chunkSize); i++) {
            subarray[i] = array.slice((i * chunkSize), (i * chunkSize) + chunkSize);
        }
        return subarray;
    }

    /** вернет только элементы с уникальными свойствами propName */
    static dedupeArrayOfObjectsByProperty<T>(arr: T[], propName: keyof T): T[] {
        if (!HelperUtil.isFilledArray(arr)) { return arr; }
        const uniqueProps = HelperUtil.dedupe(arr.map(one => one[propName]));
        return uniqueProps.map(one => HelperUtil.where(arr, propName, one));
    }

    /** откинуть дубли */
    static dedupe<T>(val: T[]) {
        return Array.isArray(val) ? Array.from(new Set(val)) : val;
    }

    /** найти строку в справочнике по какому-то полю */
    static where<T, KEY extends keyof T>(arr: T[], attr: KEY, value: T[KEY], returnEmptyObject?: boolean): T | null {
        if (!arr.length || !attr || value === null || value === undefined) { return returnEmptyObject ? <T>{} : null; }
        const ret = arr.find(function (obj) {
            if (obj[attr] === value) { return true; }
        });
        return ret ? ret : (returnEmptyObject ? <any>{} : null);
    }

    static splitTextBySpacesSemicolonsComas(str: string, deduped = false): string[] {
        if (this.isInvalidPrimitive(str)) { return null; }
        const ret = str.split(/\s|\n|,|;/g).filter(one => one !== '');
        return deduped ? this.dedupe(ret) : ret;
    }

    static enum(separator: string, ..._enum: (string | number)[]): string {
        if (!_enum) { return; }
        return _enum
            .filter(e => !this.isInvalidPrimitive(e))
            .join(separator + ' ');
    }

    static saveBlobAs(fullResponse: HttpResponse<Blob>, initialName: string) {
        const objectUrl = URL.createObjectURL(fullResponse.body);
        const aLink = document.createElement('a');
        aLink.href = objectUrl;
        aLink.download = HelperUtil.getFileNameFromHttpResponse(fullResponse, initialName);
        aLink.click();
        URL.revokeObjectURL(objectUrl);
    }

    static getFileNameFromHttpResponse<T>(httpResponse: HttpResponse<T>, initialName: string): string {
        const disposition = httpResponse.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches !== null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }
        return initialName;
    }

    /**
     * @param v
     * @example
     * // returns '09'
     * HelperUtil.formatTwoDigitsNumber(9);
     * @example
     * // returns '10'
     * HelperUtil.formatTwoDigitsNumber(10);
     * @returns
     */
    static formatTwoDigitsNumber(v: number): string {
        return v < 10 ? `0${v}` : v.toString();
    }

    /**
     * Переводит смещение в минутах для moment в формат +-HH:mm
     * @param offset
     * @example
     * // returns '+03:00'
     * HelperUtil.formatMomentOffset(-180);
     * @returns
     */
    static formatMomentOffset(offset: number): string {
        const hours = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.floor(Math.abs(offset) % 60);

        const sign = -offset >= 0 ? '+' : '-';

        return `${sign}${HelperUtil.formatTwoDigitsNumber(hours)}:${HelperUtil.formatTwoDigitsNumber(minutes)}`;
    }
}
