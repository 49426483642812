import { AuthLoginForm } from '../auth.interfaces';
import { PasswordRestoration } from '@app/core/auth/models/password-restoration';
import { ClientEntity } from '@shared/client/interfaces/client-entity.interface';
import { RegisterRequestDto } from '../dto/register.request.dto';

export class AuthLogoutAction {
    public static readonly type = '[Auth] Logout';
}
export class AuthLoginAction {
    public static readonly type = '[Auth] do Login';
    constructor(public payload: AuthLoginForm) { }
}

export class AuthGetProfileAction {
    public static readonly type = '[Auth] get Profile';
}

export class SetImpersonationAction {
    static readonly type = '[ImpersonationUsers] Set Impersonation';

    constructor(public impersonatedUser: ClientEntity) { }
}

export class ClearImpersonationAction {
    static readonly type = '[ImpersonationUsers] Clear Impersonation';
}

export class AuthSendEmailRestorePasswordAction {
    static readonly type = '[RestorePassword] Send Email';
    constructor(public email: string) { }
}

export class ClearAuthRestorePasswordAction {
    static readonly type = '[RestorePassword] Clear state';
}

export class AuthRestorePasswordAction {
    static readonly type = '[RestorePassword] do restore';
    constructor(public payload: PasswordRestoration) { }
}

export class AuthGetUserRoleAction {
    static readonly type = '[AuthState] get role';
}

export class AuthCheckImpersonationAction {
    static readonly type = '[AuthState] check impersonation';
}

export class AuthRegisterAction {
    static readonly type = '[AuthState] Set Access Token';

    constructor(public payload: RegisterRequestDto) {}
}
