import { DDDEntity } from '@app/core/ddd-layout/interfaces/ddd-entity.interface';

export class ClientPayloadModel {
    email = '';
    username = '';
    firstName = '';
    lastName = '';
    middleName = '';
    phone?: string = '';
    startDate?: Date;
    endDate?: Date;
    orgName: string = '';
    role: string = '';
    vehicleCount: number = 0;
    dashboardSettings: DDDEntity = null;
}
