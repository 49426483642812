import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AppUpdateAction } from './app.state.actions';

const INITIAL_AUTH_STATE = {
    version: '0',
    loading: false,
};

export interface AppStateModel {
    version: string;
    loading: boolean;
}

@State<AppStateModel>({
    name: 'app',
    defaults: INITIAL_AUTH_STATE,
})
@Injectable()
export class AppState {
    @Selector()
    public static version(state: AppStateModel): string {
        return state.version;
    }

    @Selector()
    public static loading(state: AppStateModel): boolean {
        return state.loading;
    }

    @Action(AppUpdateAction)
    updateAction({}: StateContext<AppStateModel>) {
        window.location.reload();
    }
}
