import { Sort } from '@angular/material/sort';
import { HelperUtil } from '@shared/helper.util';
import * as moment from 'moment';

export type SorterCompareValueFn<V = any> = (valueA: V, valueB: V) => number;
export type SorterValueGetter<T, V> = (item: T) => V;

export enum SortFieldType {
    Boolean = 'boolean',
    String = 'string',
    Date = 'date',
    Number = 'number',
    Null = 'null',
};

type SorterValueGetterMap<T, V> = Record<string, SorterValueGetter<T, V>>;

export interface SorterFieldsParam<T> extends Partial<Record<SortFieldType, SorterValueGetterMap<T, any>>> {
    [SortFieldType.Boolean]?: SorterValueGetterMap<T, boolean>;
    [SortFieldType.String]?: SorterValueGetterMap<T, string>;
    [SortFieldType.Date]?: SorterValueGetterMap<T, moment.MomentInput>;
    [SortFieldType.Number]?: SorterValueGetterMap<T, number>;
}

export interface SorterListSortParams<T> {
    /**
     *  @example
     *  ```
     *  const params: SorterListSortParams = {
     *      fields: {
     *          // тип поля сортировки
     *          string: {
     *              // название поля сортировки: геттер значения
     *              name: item => item.name,
     *          },
     *          date: {
     *              createdAt: item => item.createdAt,
     *          },
     *          number: {
     *              nameLength: item => item.name.length,
     *          },
     *      },
     *  }
     *  ```
     */
    fields: SorterFieldsParam<T>;
}

/**
 *  Сортировка массивов на стороне фронта
 */
export class SorterUtil<T> {
    /**
     *  Маппинг типов полей и функций сравнения
     *
     *  Если функция сравнения возвращает 0 (поля равны), вызывается следующая
     *
     *  @example
     *  ```
     *  {
     *      boolean: [compareFn1, compareFn2, ..., compareFnN],
     *  }
     *  ```
     */
    protected compareFnFallbackMap: Record<SortFieldType, SorterCompareValueFn[]> = {
        [SortFieldType.Boolean]: [ this.compareNulls, this.compareBoolean ],
        [SortFieldType.Date]: [ this.compareNulls, this.compareDates ],
        [SortFieldType.Number]: [ this.compareNulls, this.compareNumber ],
        [SortFieldType.String]: [ this.compareNulls, this.compareString ],
        [SortFieldType.Null]: [],
    };

    constructor(
        protected params: SorterListSortParams<T>
    ) { }

    public sortList(list: T[], sort: Sort): T[] {
        if (!sort?.active || !sort?.direction) {
            return list;
        }

        const fieldType = this.getFieldType(sort.active);
        const compareFn = this.makeCompareFn(sort, this.compareFnFallbackMap[fieldType]);

        return [ ...list ].sort(compareFn);
    }

    protected makeCompareFn(sort: Sort, fallback: SorterCompareValueFn[]) {
        const compareChain = (value1: any, value2: any) => {
            for (const compareFn of fallback) {
                const res = compareFn(value1, value2);
                if (res !== 0) {
                    return res;
                }
            }

            return 0;
        };

        return (item1: T, item2: T) => {
            const value1 = this.getItemValue(item1, sort.active);
            const value2 = this.getItemValue(item2, sort.active);

            return sort.direction === 'asc'
                ? compareChain(value1, value2)
                : compareChain(value2, value1);
        };
    }

    protected getItemValue(item: T, field: string): any {
        const fieldType = this.getFieldType(field);
        const customGetter = this.params?.fields[fieldType]?.[field];

        if (typeof customGetter === 'function') {
            return customGetter(item);
        }

        return item[field];
    }

    protected getFieldType(field: string): SortFieldType | null {
        let fieldType: SortFieldType;

        for (fieldType in this.params.fields) {
            const fields = Object.keys(this.params.fields[fieldType] ?? {});

            if (fields.includes(field)) {
                return fieldType;
            }
        }

        return SortFieldType.Null;
    }

    protected compareString(a: string, b: string) {
        return a > b ? 1 : (a === b ? 0 : -1);
    }
    protected compareNumber(a: number, b: number) {
        return (+a) - (+b);
    }
    protected compareBoolean(a: boolean, b: boolean) {
        return (+a) - (+b);
    }
    protected compareDates(a: moment.MomentInput, b: moment.MomentInput) {
        const aDate = moment(a);
        const bDate = moment(b);

        return aDate > bDate ? 1 : (aDate === bDate ? 0 : -1);
    };

    protected compareNulls(a: any, b: any): number {
        return HelperUtil.isInvalidPrimitive(a) ? -1
            : (HelperUtil.isInvalidPrimitive(b) ? 1 : 0);
    }
}
