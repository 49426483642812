export enum CarStatus {
    NoSignal = 'noSignal',
    OnTheRoad = 'onTheRoad',
    Discharged = 'discharged',
    Parking = 'parking',
    Towing = 'towing',
    Warning = 'warning',
    OnRent = 'onRent',
    RentCompleted = 'rentCompleted'
}
