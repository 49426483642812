import { Injectable } from '@angular/core';
import { CarStatus } from '@app/pages/cars/store/enum/car-status.enum';

interface CarStatusParams {
    triggerTime?: boolean;
    ignition?: boolean;
}

@Injectable()
export class CarsStatusService {
    public getCarStatus(params: CarStatusParams): CarStatus {
        return params.triggerTime
            ? CarStatus.NoSignal
            : params.ignition === false
                ? CarStatus.Parking
                : params.ignition === true
                    ? CarStatus.OnTheRoad
                    : CarStatus.Warning;
    }
}
