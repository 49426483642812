import { NgModule } from '@angular/core';
import { MessageBarComponent } from './message-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MessageBarService } from './message-bar.service';

@NgModule({
    imports: [
        MatSnackBarModule,
        MatIconModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [ MessageBarComponent ],
    declarations: [ MessageBarComponent ],
    providers: [ MessageBarService ],
})
export class MessageBarModule {
}
