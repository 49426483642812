import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class MonitoringApiQueryService {
    constructor(private httpClient: HttpClient) {  }
    getVehicleList(rec?: number) {
        const body = {
            activeFilters: [],
            externalFilter: { model: null,
                city: null,
                dealer: null,
                product: null,
                problems: [],
            },
            onPage: rec ? rec : 10000,
            page: 0,
            pipes: [],
            search: '',
            sort: null,
        };
        return this.httpClient.post('/vehicles', body).toPromise().then((result) => {
            return result;
        });
    }
}
