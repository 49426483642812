import { CarEventsValueIndicator } from '@app/pages/cars/store/interfaces/car-events-value-indicator.interface';
import { CarEventsIndicator } from '@app/pages/cars/store/interfaces/car-events-indicator.interface';

export class InterfaceCurCarEvents {
    latitude: number;
    longitude: number;
    angle_direction: number;
    valueIndicator: CarEventsValueIndicator;
    indicator: CarEventsIndicator;
}
