import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HelperUtil } from "@shared/helper.util";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DddListExportRequestDto } from "../dto/ddd-list-export.request.dto";

@Injectable()
export class DddListExportQueryService {
    constructor(
        private readonly httpClient: HttpClient
    ) { }

    export(entityType: string, payload: DddListExportRequestDto): Observable<void> {
        const url = `/bmo/ddd/export/${entityType}`;
        return this.httpClient.post(url, payload, {
            responseType: 'blob',
            observe: 'response',
        }).pipe(
            map(response => HelperUtil.saveBlobAs(response, `${entityType}.xlsx`))
        );
    }
}
