import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { VehiclesGetPageAction } from './monitoring-api.action';
import { MonitoringApiQueryService } from './monitoring-api.query-service';
import { CarsGetPageAction } from '../../../pages/cars/store/cars.state.actions';
import { CarsState } from '../../../pages/cars/store/cars.state';

const INITIAL_CARS_STATE = {
    vehicles: {},
};

export interface MonitoringApiStateModel {
    vehicles: any
}

@State<MonitoringApiStateModel>({
    name: 'monitoringApi',
    defaults: INITIAL_CARS_STATE,
}) @Injectable()
export class MonitoringApiState {
    constructor(private monitoringApiQueryService: MonitoringApiQueryService,
        private store: Store
    ) {
    }


    @Selector()
    public static getState(state: MonitoringApiStateModel) {
        return state;
    }

    @Action(VehiclesGetPageAction)
    async vehiclesGetPageAction({ patchState, dispatch }: StateContext<MonitoringApiStateModel>) {
        await dispatch(new CarsGetPageAction(0)).toPromise().then(() => {
            const carsFromState = this.store.selectSnapshot(CarsState.cars);
            patchState({
                vehicles: { rows: carsFromState },
            });
        });
        return;
    }
}
