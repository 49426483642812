import { Injectable } from '@angular/core';
import { AuthLoginForm, AuthResponse } from '../auth.interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseStatus } from '@shared/models/responses';
import { PasswordRestoration } from '@app/core/auth/models/password-restoration';
import { RolePayload } from '../../ddd-layout/interfaces/role-payload.interface';
import { DDDEntity } from '../../ddd-layout/interfaces/ddd-entity.interface';
import { DDDLayoutQueryService } from '../../ddd-layout/services/ddd-layout-query.service';
import { map, tap } from 'rxjs/operators';
import { AuthProfileResponseDto } from '../dto/auth-profile.response.dto';
import { RegisterRequestDto } from '../dto/register.request.dto';
import { SendEmailCodeResponseDto } from '../dto/send-email-code.response.dto';
import { SendEmailCodeRequestDto } from '../dto/send-email-code.request.dto';
import { CheckEmailCodeRequestDto } from '../dto/check-email-code.request.dto';
import { CheckEmailCodeResponseDto } from '../dto/check-email-code.response.dto';
import { SendPhoneCodeRequestDto } from '../dto/send-phone-code.request.dto';
import { SendPhoneCodeResponseDto } from '../dto/send-phone-code.response.dto';
import { CheckPhoneCodeRequestDto } from '../dto/check-phone-code.request.dto';
import { CheckPhoneCodeResponseDto } from '../dto/check-phone-code.response.dto';
import { UpdateTemporaryPasswordRequestDto } from '../dto/update-temporary-password.request.dto';
import { UpdateTemporaryPasswordResponseDto } from '../dto/update-temporary-password.response.dto';

@Injectable()
export class AuthQueryService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly dddQueryService: DDDLayoutQueryService
    ) { }

    login(payload: AuthLoginForm): Observable<AuthResponse> {
        const url = '/bmo/auth/login';
        return this.httpClient.post<AuthResponse>(url, {
            email: payload.login,
            password: payload.password,
        });
    }

    profile(): Observable<AuthProfileResponseDto> {
        const url = '/bmo/auth/profile';
        return this.httpClient.get<AuthProfileResponseDto>(url);
    }

    impersonatedUserToken(email: string): Observable<{ accessToken: string }> {
        const url = '/bmo/auth/impersonate';

        return this.httpClient.post<{ accessToken: string }>(url, { email });
    }

    sendEmailRestorePassword(email: string): Observable<ResponseStatus> {
        const url = '/bmo/auth/passwordRestore';

        return this.httpClient.get<ResponseStatus>(url, { params: { email } });
    }

    restorePassword(d: PasswordRestoration): Observable<ResponseStatus> {
        const url = '/bmo/auth/passwordRestore';

        return this.httpClient.post<ResponseStatus>(url, d);
    }

    getRole(userId: string): Observable<DDDEntity<RolePayload>> {
        return this.dddQueryService.getEntity('client', userId, [ 'role' ]).pipe(
            map(response => response?.role?.[0])
        );
    }

    checkImpersonation(userId: string): Observable<boolean> {
        return this.dddQueryService.getEntities('client', {
            aggregators: [
                {
                    type: 'impersonation',
                    aggregators: [
                        {
                            type: 'client',
                            pagination: {
                                onPage: 1,
                                page: 0,
                            },
                        },
                    ],
                },
            ],
            filters: { ids: [ userId ] },
        }).pipe(
            map(response => response.data.client?.[0]?.impersonation?.[0]?.clientPagination?.total),
            map(impersonationClients => impersonationClients > 0)
        );
    }

    register(payload: RegisterRequestDto): Observable<AuthResponse> {
        const url = '/bmo/auth/registration';
        return this.httpClient.post<AuthResponse>(url, payload).pipe(
            tap(response => this.dddQueryService.checkResponse(response))
        );
    }

    sendEmailCode(payload: SendEmailCodeRequestDto): Observable<SendEmailCodeResponseDto> {
        const url = '/bmo/auth/code/email/send';
        return this.httpClient.post<SendEmailCodeResponseDto>(url, payload).pipe(
            tap(response => this.dddQueryService.checkResponse(response))
        );
    }

    checkEmailCode(payload: CheckEmailCodeRequestDto): Observable<CheckEmailCodeResponseDto> {
        const url = '/bmo/auth/code/email/check';
        return this.httpClient.post<CheckEmailCodeResponseDto>(url, payload).pipe(
            tap(response => this.dddQueryService.checkResponse(response))
        );
    }

    sendPhoneCode(payload: SendPhoneCodeRequestDto): Observable<SendPhoneCodeResponseDto> {
        const url = '/bmo/auth/code/phone/send';
        return this.httpClient.post<SendPhoneCodeResponseDto>(url, payload).pipe(
            tap(response => this.dddQueryService.checkResponse(response))
        );
    }

    checkPhoneCode(payload: CheckPhoneCodeRequestDto): Observable<CheckPhoneCodeResponseDto> {
        const url = '/bmo/auth/code/phone/check';
        return this.httpClient.post<CheckPhoneCodeResponseDto>(url, payload).pipe(
            tap(response => this.dddQueryService.checkResponse(response))
        );
    }

    updateTemporaryPassword(payload: UpdateTemporaryPasswordRequestDto): Observable<UpdateTemporaryPasswordResponseDto> {
        const url = '/bmo/auth/password/temporary';
        return this.httpClient.put<UpdateTemporaryPasswordResponseDto>(url, payload).pipe(
            tap(response => this.dddQueryService.checkResponse(response))
        );
    }
}
