import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitoringApiQueryService } from './store/monitoring-api.query-service';
import { NgxsModule } from '@ngxs/store';
import { MonitoringApiState } from './store/monitoring-api.state';
import { CarsState } from '../../pages/cars/store/cars.state';
import { CarsQueryService } from '../../pages/cars/store/cars-query.service';
import { CarsBmoQueryService } from '@shared/cars/services/cars-bmo-query.service';
import { CarsStatusService } from '@shared/cars/services/cars-status.service';
import { DddListExportQueryService } from '@shared/ddd-entity/store/services/ddd-list-export-query.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgxsModule.forFeature([
            MonitoringApiState, CarsState ]),
    ],
    providers: [
        MonitoringApiQueryService,
        CarsQueryService,
        CarsBmoQueryService,
        CarsStatusService,
        DddListExportQueryService,
    ],
})
export class MonitoringApiModule { }
