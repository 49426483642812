export class ShowFullMenuAction {
    public static readonly type = '[Layout] show captions';
}

export class HideFullMenuAction {
    public static readonly type = '[Layout] hide captions';
}

export class ToggleFullMenuAction {
    public static readonly type = '[Layout] toggle captions';
}
