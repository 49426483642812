import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MonitoringVehicleStatusesResponseDto } from '../dto/monitoring-vehicle-statuses.response.dto';

@Injectable({
    providedIn: 'root',
})
export class MonitoringStatisticsQueryService {
    constructor(private readonly httpClient: HttpClient) {
    }

    getVehicleStatuses(): Observable<MonitoringVehicleStatusesResponseDto> {
        const url = '/bmo/monitoring/vehicleStatus';
        return this.httpClient.get<MonitoringVehicleStatusesResponseDto>(url);
    }
}
