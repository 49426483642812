import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthGetProfileAction } from '../store/auth.actions';
import { AuthState } from '../store/auth.state';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public profileLoaded$ = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly store: Store
    ) {
        this.store.select(AuthState.accessToken).pipe(
            tap(() => this.setProfileLoaded(false)),
            switchMap(() => this.store.dispatch(new AuthGetProfileAction())),
            tap(() => this.setProfileLoaded(true))
        ).subscribe();
    }

    getCurrentUsername(): string {
        const impersonatedUser = this.store.selectSnapshot(AuthState.getImpersonatedUser);
        const profile = this.store.selectSnapshot(AuthState.profile);

        return impersonatedUser?.email ?? profile.username;
    }

    setProfileLoaded(val: boolean): void {
        this.profileLoaded$.next(val);
    }
}
