import { Component, isDevMode, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { UpdaterComponent } from './core/updater/updater.component';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './core/auth/services/auth.service';


@Component({
    selector: 'hml-root', template: '<hml-mobility-route-handler></hml-mobility-route-handler>',
})
export class AppComponent implements OnInit {
    private snackBarDNotOpen = true;

    constructor(private readonly swUpdate: SwUpdate,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly titleService: Title,
        private readonly _snackBar: MatSnackBar,
        private readonly authService: AuthService
    ) {
    }

    ngOnInit() {
        this.swUpdate.available.subscribe(() => {
            this.openUpdateBar();
        });

        if (!isDevMode()) {
            setInterval(() => {
                this.swUpdate.checkForUpdate().then();
            }, 60000);
        }

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route: ActivatedRoute) => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }),
            // так же мы выбираем только первичные аутлеты (опционально)
            filter((route: ActivatedRoute) => route.outlet === 'primary'),
            mergeMap((route: ActivatedRoute) => route.data)
            //задаем
        ).subscribe(routerData => {
            this.titleService.setTitle(routerData?.title || 'Fleet');
        });
    }


    openUpdateBar() {
        this._snackBar.openFromComponent(UpdaterComponent, {
            panelClass: 'updateMessage',
            verticalPosition: 'bottom',
            horizontalPosition: 'start',
        });
        this.snackBarDNotOpen = false;
    }
}
