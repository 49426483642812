import { Injectable, NgZone } from '@angular/core';
import { MessageBarComponent } from './message-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MessageBarService {
    constructor(
        private _snackBar: MatSnackBar,
        private ngZone: NgZone
    ) {
    }

    info(message: string, duration = 4000): void {
        this.message(message, duration, 'info-message', 'info');
    }

    good(message: string, duration = 4000): void {
        this.message(message, duration, 'message-bar-good', 'check_circle');
    }

    warn(message: string, duration = 3000): void {
        this.message(message, duration, 'message-bar-warn', 'info');
    }

    private message(message: string, duration: number, panelClass: string, icon: string): void {
        this.ngZone.run(() => {
            this._snackBar.openFromComponent(MessageBarComponent, {
                data: { message, icon },
                panelClass,
                duration,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
            });
        });
    }
}
