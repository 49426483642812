import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

interface MessageBarData {
    message: string;
    icon: string;
}

@Component({
    selector: 'hml-message-bar',
    templateUrl: './message-bar.component.html',
    styleUrls: [ './message-bar.component.scss' ],
})

export class MessageBarComponent {
    constructor(
        private _snackBar: MatSnackBar,
        @Inject(MAT_SNACK_BAR_DATA) public data: MessageBarData
    ) {
    }

    close() {
        this._snackBar._openedSnackBarRef.dismiss();
    }
}
