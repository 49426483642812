import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    CarsColumnsAction,
    CarsFiltersAction,
    CarsGetPageAction,
    CarsPaginatorEventAction,
    CarsSearchAction,
    CarsSortAction,
    CarsLoadVehicleStatusCounts,
    CarsGetCarEvents,
    SetMileage,
    CarsGetSimIdByVinCar,
    CarsGetVehicles,
    CarsGetVehicleMainInfo,
    CarsLinkVehicleToPark,
    CarsClearStatusFilter,
    CarsSelectStatusFilter,
    CarsDeselectStatusFilter,
    CarsToggleStatusFilter,
    CarsChangeShowByParks,
    CarsSelectPark,
    CarsDeselectPark,
    CarsToggleParkSelected,
    CarsSetParkRemoveAllowed,
    CarsToggleParkRemoveAllowed,
    CarsRemovePark,
    CarsExportList,
} from './cars.state.actions';
import { TableFilterItem } from '@shared/fleet-table/interfaces/data-query-service.interface';
import { FLEET_TABLE_INITIAL, FleetTable } from '@shared/fleet-table/interfaces/fleet-table.interface';
import { CarsQueryService } from './cars-query.service';
import { Sort } from '@angular/material/sort';
import { FleetVehicle } from './interfaces/fleet-vehicle.interface';
import { RefItem } from '@shared/interfaces/ref-item.interface';
import { FleetVehicleFullInfo } from '@app/pages/cars/store/interfaces/fleet-vehicle-full-info.interface';
import { catchError, map, tap, take, switchMap } from 'rxjs/operators';
import { Observable, EMPTY, throwError } from 'rxjs';
import { MonitoringVehicleStatusesResponseDto } from '@monitoringModule/state/dto/monitoring-vehicle-statuses.response.dto';
import { MonitoringStatisticsQueryService } from '@monitoringModule/state/services/monitoring-statistics-query.service';
import { HttpClient } from '@angular/common/http';
import { InterfaceIndicator } from '@app/pages/cars/store/interfaces/indicator.interface';
import { InterfaceCurCarEvents } from '@app/pages/cars/store/interfaces/cur-car-events.interface';
import { DDDLayoutQueryService } from '@app/core/ddd-layout/services/ddd-layout-query.service';
import { Vehicle } from '@app/pages/cars/store/interfaces/vehicle.interface';
import { VehicleMainInfo } from '@app/pages/cars/store/interfaces/vehicle-main-info.interface';
import {
    DDDEntitySelectAggregator,
    DDDEntitySelectRequestDto,
} from '@app/core/ddd-layout/dto/ddd-entity-select.request.dto';
import { registrationNumberLike } from '@app/pages/cars/store/filters';
import { ERole } from '@shared/app-roles.const';
import { CarsBmoQueryService } from '@shared/cars/services/cars-bmo-query.service';
import { DDDEntity } from '@app/core/ddd-layout/interfaces/ddd-entity.interface';
import { ClientEntity } from '@shared/client/interfaces/client-entity.interface';
import { CarsStatusService } from '@shared/cars/services/cars-status.service';
import { CarStatus } from './enum/car-status.enum';
import { SorterUtil } from '@shared/utils/sorter.util';
import { CarParkRef } from './interfaces/car-park-ref.interface';
import { DDDBaseResponseDto } from '@app/core/ddd-layout/dto/ddd-base-response.dto';
import { DddListExportEntityDto } from '@shared/ddd-entity/store/dto/ddd-list-export.request.dto';
import { DddListExportQueryService } from '@shared/ddd-entity/store/services/ddd-list-export-query.service';
import { getCarStatusName } from './const/car-status-names.const';

const INITIAL_CARS_STATE: CarsStateModel = {
    ...FLEET_TABLE_INITIAL,
    cars: [],
    showByParks: false,
    search: '',
    searchResultText: 'Без результатов',
    statusRef: [],
    transportPark: {},
    carEvents: [],
    indicator: [],
    curCarEvents: new InterfaceCurCarEvents(),
    carSimId: null,
    vehicles: [],
    carParks: [],
    selectedParkIds: [],
    vehicleMainInfo: null,
    loader: false,
    currentUserId: '',
    statusFilter: [],
    sort: {
        active: 'license',
        direction: 'asc',
    },
    parkRemoveAllowed: false,
};

export interface CarsStateModel extends FleetTable {
    sort: Sort;
    cars: FleetVehicle[],
    showByParks: boolean;
    search: string;
    searchResultText: string;
    statusRef: RefItem[];
    transportPark: Record<string, string>;
    vehicleStatusCounts?: MonitoringVehicleStatusesResponseDto;
    carEvents: InterfaceCurCarEvents[];
    indicator: InterfaceIndicator[];
    curCarEvents: InterfaceCurCarEvents;
    carSimId: string;
    vehicles: Vehicle[];
    carParks: CarParkRef[];
    selectedParkIds: string[];
    vehicleMainInfo: VehicleMainInfo;
    loader: boolean;
    currentUserId: string;
    statusFilter: CarStatus[];
    parkRemoveAllowed: boolean;
}

@State<CarsStateModel>({
    name: 'cars',
    defaults: INITIAL_CARS_STATE,
})
@Injectable()
export class CarsState {
    public static carSorter = new SorterUtil<Vehicle>({
        fields: {
            string: {
                carBrand: v => v.carBrand,
                model: v => v.model,
                license: v => v.license,
                vin: v => v.vin,
                city: v => v.city,
                transportPark: v => v.transportPark,
            },
            date: {
                rentStart: v => v.rentStart,
                planRentEnd: v => v.planRentEnd,
            },
            number: {
                rentalPeriod: v => v.rentalPeriod,
                drivers: v => v.drivers,
                contractMileage: v => v.contractMileage,
                mileage: v => v.mileage,
                groupsCount: v => v.groups.length,
            },
        },
    });

    constructor(private readonly carsQueryService: CarsQueryService,
        private readonly dddQueryService: DDDLayoutQueryService,
        private readonly monitoringStatisticsQuery: MonitoringStatisticsQueryService,
        private readonly httpClient: HttpClient,
        private readonly carsBmoQueryService: CarsBmoQueryService,
        private readonly carStatusService: CarsStatusService,
        private readonly dddListExportQuery: DddListExportQueryService,
        private readonly store: Store) {
    }

    @Selector()
    public static searchResultText(state: CarsStateModel): string {
        return state.searchResultText;
    }

    @Selector()
    static getVehicleStatusCounts(state: CarsStateModel): MonitoringVehicleStatusesResponseDto {
        return state.vehicleStatusCounts;
    }

    @Selector()
    static getTransportPark(state: CarsStateModel): Record<string, string> {
        return state.transportPark;
    }

    @Selector()
    static getCarsFullInfo(state: CarsStateModel): FleetVehicleFullInfo[] {
        return state.cars.map((car) => {
            const transportPark = state.transportPark[car.vin];
            return { ...car, transportPark };
        });
    }

    @Selector()
    static getCurCarEvents(state: CarsStateModel) {
        return state.curCarEvents;
    }

    @Selector()
    static getCarEvents(state: CarsStateModel) {
        return state.carEvents;
    }

    @Selector()
    static getCarSimId(state: CarsStateModel): string {
        return state.carSimId;
    };

    @Selector()
    static getVehiclesFiltered(state: CarsStateModel): Vehicle[] {
        let result = state.statusFilter.length > 0
            ? state.vehicles.filter(vehicle => {
                const isOnRent = state.statusFilter.includes(<CarStatus>'onRent');
                const isRentCompleted = state.statusFilter.includes(<CarStatus>'rentCompleted');

                if (isOnRent) {
                    return vehicle.planRentEnd >= new Date().toISOString() || state.statusFilter.includes(vehicle.status);
                }

                if (isRentCompleted) {
                    return vehicle.planRentEnd < new Date().toISOString() || state.statusFilter.includes(vehicle.status);
                }

                return state.statusFilter.includes(vehicle.status);
            })
            : state.vehicles;

        result = state.showByParks
            ? result.filter(v => v.groups.some(g => state.selectedParkIds.includes(g)))
            : result;

        return result;
    }

    @Selector([ CarsState.getVehiclesFiltered ])
    static getVehicles(state: CarsStateModel, filteredList: Vehicle[]): Vehicle[] {
        const skip = state.onPage * state.page;
        return CarsState.carSorter.sortList(filteredList, state.sort)
            .slice(skip, skip + state.onPage);
    };

    @Selector()
    static getCarParkRefs(state: CarsStateModel): CarParkRef[] {
        return state.carParks;
    };

    @Selector()
    static getVehicleMainInfo(state: CarsStateModel) {
        return state.vehicleMainInfo;
    };

    @Selector()
    public static cars(state: CarsStateModel): FleetVehicle[] {
        return state.cars;
    }

    @Selector()
    public static getShowByParks(state: CarsStateModel): boolean {
        return state.showByParks;
    }

    @Selector()
    public static getSelectedParkIds(state: CarsStateModel): string[] {
        return state.selectedParkIds;
    }

    @Selector([ CarsState.getVehiclesFiltered ])
    public static total(state: CarsStateModel, filteredList: Vehicle[]): number {
        return filteredList?.length ?? 0;
    }

    @Selector()
    public static loader(state: CarsStateModel): boolean {
        return state.loader;
    }

    @Selector()
    public static page(state: CarsStateModel): number {
        return state.page;
    };

    @Selector()
    public static onPage(state: CarsStateModel): number {
        return state.onPage;
    };

    @Selector()
    public static search(state: CarsStateModel): string {
        return state.search;
    };

    @Selector()
    public static filters(state: CarsStateModel): TableFilterItem[] {
        return state.filters;
    };

    @Selector()
    public static columns(state: CarsStateModel): string[] {
        return state.columns;
    };

    @Selector()
    public static sort(state: CarsStateModel): Sort {
        return state.sort;
    };

    @Selector()
    public static statusRef(state: CarsStateModel): RefItem[] {
        return state.statusRef;
    };

    @Selector()
    public static statusFilter(state: CarsStateModel): CarStatus[] {
        return state.statusFilter;
    }

    @Selector()
    public static getParkRemoveAllowed(state: CarsStateModel): boolean {
        return state.parkRemoveAllowed;
    }

    @Action(CarsLoadVehicleStatusCounts, { cancelUncompleted: true })
    loadVehicleStatusCounts({ patchState }: StateContext<CarsStateModel>): Observable<MonitoringVehicleStatusesResponseDto> {
        return this.monitoringStatisticsQuery.getVehicleStatuses()
            .pipe(tap(res => patchState({ vehicleStatusCounts: res })));
    }

    @Action(CarsGetCarEvents)
    carEvents({ patchState }: StateContext<CarsStateModel>, { payLoad }: CarsGetCarEvents) {
        const callUrl = '/mta/carv2/carEvents';
        return this.httpClient.post(callUrl, { sim_id: payLoad.sim_id })
            .pipe(
                map((response: any) => {
                    patchState(
                        {
                            carEvents: response,
                            curCarEvents: response?.events[0] ? response.events[0] : null,
                            indicator: response.indicator,
                        }
                    );
                }),
                catchError(() => {
                    patchState({
                        carEvents: INITIAL_CARS_STATE.carEvents,
                        curCarEvents: INITIAL_CARS_STATE.curCarEvents,
                        indicator: INITIAL_CARS_STATE.indicator,
                    });
                    return EMPTY;
                })
            );
    }

    @Action(SetMileage)
    setMileage({ patchState }: StateContext<CarsStateModel>, { payLoad }: SetMileage) {
        const callUrl = '/mta/manage/set-mileage';
        patchState({ loader: true });
        return this.httpClient.post(callUrl, {
            deviceId: payLoad.deviceId,
            mileage: payLoad.mileage,
        }).pipe(take(1)).subscribe(() => {
            patchState({ loader: false });
        }, () => {
            patchState({ loader: false });
        });
    }

    @Action(CarsSearchAction)
    searchChangeAction({ patchState, dispatch }: StateContext<CarsStateModel>, { payload }: CarsSearchAction) {
        patchState({ search: payload });
        dispatch(new CarsGetVehicles(0));
    }


    @Action(CarsGetPageAction)
    async getPageAction({ patchState, getState }: StateContext<CarsStateModel>, { payload }: CarsGetPageAction) {
        const {
            onPage,
            search,
            filters,
            sort,
        } = getState();
        const carsDto = await this.carsQueryService.get(payload, onPage, search, filters, sort);
        patchState({ page: carsDto.page, cars: carsDto.rows, total: carsDto.rowCount || 0 });
        return;
    }

    @Action(CarsGetVehicles, { cancelUncompleted: true })
    getVehicles({ patchState, getState }: StateContext<CarsStateModel>, { currentUserId }: CarsGetVehicles) {
        if (currentUserId) {
            patchState({ currentUserId });
        }

        const state = getState();

        if (!state.currentUserId) {
            return;
        }

        const carAggregator: DDDEntitySelectAggregator = {
            type: 'car',
            aggregators: [
                {
                    type: 'car_props',
                },
                {
                    type: 'city',
                },
                {
                    type: 'client',
                    aggregators: [
                        {
                            type: 'role',
                        },
                    ],
                },
            ],
            filters: {
                ids: [],
                fields: [
                    ...state.search ? [ registrationNumberLike(state.search) ] : [],
                ],
            },
        };

        const entitiesParams: DDDEntitySelectRequestDto = {
            aggregators: [
                carAggregator,
                {
                    type: 'park',
                    aggregators: [ carAggregator ],
                },
            ],
            filters: {
                ids: [ state.currentUserId ],
            },
        };

        return this.dddQueryService.getEntities('client', entitiesParams).pipe(
            map(response => {
                const vehicles: Record<string, DDDEntity> = {};

                response.data.client[0]?.car?.forEach(vehicle => vehicles[vehicle.id] = vehicle);

                const parks: DDDEntity[] = response.data.client[0]?.park ?? [];
                const parkRefs: CarParkRef[] = response.data.client[0]?.park.map((item) => ({
                    id: item.id,
                    name: item.payload?.name,
                    count: item.car?.length ?? 0,
                    selectedIds: item.car?.map(i => i.id) ?? [],
                })) ?? [];

                parks.forEach(park => park?.car?.forEach(vehicle => vehicles[vehicle.id] = vehicle));

                patchState({
                    carParks: parkRefs,
                });

                return {
                    parks,
                    vehicles: Object.values(vehicles),
                };
            }),
            switchMap((item) => {
                const deviceIds = item.vehicles.map(vehicle => vehicle.payload?.deviceId ?? vehicle.payload?.sim_id).filter(i => !!i);

                return this.carsBmoQueryService.getCarsStatusInfo({ deviceIds }).pipe(
                    map(statusResult => {
                        return item.vehicles.map(vehicle => {
                            const deviceId = vehicle.payload?.deviceId || vehicle.payload?.sim_id;
                            const statusRow = statusResult?.find(device => device.deviceId === deviceId);
                            const vehicleParks = item.parks?.filter(
                                park => park?.car.find(car => car.id === vehicle.id)
                            );
                            const vehicleParkIds = vehicleParks.map((vehicle) => vehicle.id);

                            const deal = vehicle.payload?.deals?.[0];

                            const rentalPeriod = typeof deal?.rentalPeriod === 'string'
                                ? +deal?.rentalPeriod?.split(',')[0]
                                : typeof deal?.rentalPeriod === 'number'
                                    ? deal?.rentalPeriod
                                    : null;

                            const mileage = statusRow?.mileage
                                ? +statusRow?.mileage
                                : null;

                            const contractMileage = typeof deal?.contractMileage === 'string'
                                ? +deal.contractMileage.replace(/\s/gi, '').split(',')[0]
                                : deal?.contractMileage;

                            return {
                                sim_id: deviceId,
                                deviceId,
                                rentalPeriod,
                                mileage,
                                id: vehicle.id,
                                carBrand: vehicle.payload?.carBrand,
                                model: vehicle.payload?.model,
                                license: vehicle.payload?.license || vehicle.payload?.registrationNumber,
                                vin: vehicle.payload?.vin,
                                groups: vehicleParkIds,
                                rentStart: deal?.rentStart,
                                planRentEnd: deal?.planRentEnd,
                                contractMileage,
                                city: vehicle.city?.[0]?.payload?.name,
                                transportPark: vehicle.payload?.dialerName,
                                ignition: statusRow?.ignition ?? null,
                                triggerTime: statusRow?.triggerTime ?? null,
                                drivers: vehicle.client?.filter((client: ClientEntity) => client.role?.[0]?.payload?.ident === ERole.Driver)?.length ?? 0,
                                rgb_code: null,
                                status: this.carStatusService.getCarStatus({
                                    triggerTime: statusRow?.triggerTime ?? null,
                                    ignition: statusRow?.ignition ?? null,
                                }),
                            };
                        });
                    }),
                    catchError(error => {
                        return throwError(error);
                    })
                );
            }),
            tap(vehicles => patchState({
                vehicles,
                total: vehicles.length,
            })),
            catchError(() => {
                patchState({
                    vehicles: [],
                });
                return EMPTY;
            })
        );
    }

    @Action(CarsExportList, { cancelUncompleted: true })
    exportList(): Observable<void> {
        const vehicles = this.store.selectSnapshot(CarsState.getVehiclesFiltered);

        const entitiesToExport: DddListExportEntityDto[] = vehicles.map(vehicle => ({
            id: vehicle.id,
            additionalInfo: {
                drivers: `${vehicle.drivers ?? 0}`,
                status: getCarStatusName(vehicle.status),
                mileage: `${vehicle.mileage ?? 0}`,
            },
        }));

        return this.dddListExportQuery.export('car', {
            entities: entitiesToExport,
            reportType: 'car_report',
            aggregators: [
                { type: 'city' },
                { type: 'park' },
            ],
        });
    }

    @Action(CarsGetVehicleMainInfo)
    getVehicleMainInfo({ patchState }: StateContext<CarsStateModel>, { payload }: CarsGetVehicleMainInfo) {
        return this.dddQueryService.getEntity('car', payload, [ 'car_props' ]).pipe(
            map((item) => {
                const vehicleMainInfo: VehicleMainInfo = {
                    id: item.id,
                    sim_id: item.payload?.simId || item.payload?.deviceId,
                    carBrand: item.payload?.carBrand,
                    model: item.payload?.model,
                    license: item.payload?.license || item.payload?.registrationNumber,
                    vin: item.payload?.vin,
                    deviceId: item.payload?.deviceId,
                    sts_number: item.payload?.stsNumber,
                    rgb_code: item.car_props[0]?.payload?.external?.hexColor,
                    color_i18n: {
                        ru: item.car_props[0]?.payload?.exteriorColor,
                    },
                    interior_i18n: {
                        ru: item.car_props[0]?.payload.external.interiorColorCode,
                    },
                };

                patchState({
                    vehicleMainInfo,
                });
            })
        );
    }

    @Action(CarsGetSimIdByVinCar)
    async getSimIdByVinCar({ patchState }: StateContext<CarsStateModel>, { payLoad }: CarsGetSimIdByVinCar) {
        const { rows } = await this.carsQueryService.get(0, 100, payLoad);
        const carSimId = rows[0].sim_id;
        patchState({ carSimId });
    }

    @Action(CarsLinkVehicleToPark)
    linkVehicleToPark({ }: StateContext<CarsStateModel>, { vehicle, groupId, isSelected }: CarsLinkVehicleToPark) {
        return isSelected ? this.dddQueryService.link(vehicle.id, [ groupId ]) : this.dddQueryService.unlink(vehicle.id, [ groupId ]);
    }

    @Action(CarsPaginatorEventAction)
    setOnPageAction({ getState, patchState }: StateContext<CarsStateModel>, { payload }: CarsPaginatorEventAction) {
        const state = getState();
        if (state.onPage !== payload.pageSize || payload.pageIndex !== state.page) {
            patchState({ onPage: payload.pageSize, page: payload.pageIndex });
        }
    }

    @Action(CarsSortAction)
    sortAction({ patchState }: StateContext<CarsStateModel>, { payload }: CarsSortAction) {
        patchState({
            sort: payload,
        });
    }

    @Action(CarsFiltersAction)
    filtersAction({ dispatch, patchState }: StateContext<CarsStateModel>, { payload }: CarsFiltersAction) {
        patchState({ filters: payload });
        dispatch(new CarsGetPageAction(0));
    }

    @Action(CarsColumnsAction)
    columnsAction({ patchState }: StateContext<CarsStateModel>, { payload }: CarsColumnsAction) {
        patchState({ columns: payload });
    }

    @Action(CarsSelectStatusFilter)
    selectStatusFilter(
        { patchState, getState }: StateContext<CarsStateModel>,
        { status }: CarsSelectStatusFilter
    ): void {
        const state = getState();
        const statusFilter = [ ...state.statusFilter ];

        const existedIndex = statusFilter.indexOf(status);

        if (existedIndex === -1) {
            statusFilter.push(status);
            patchState({ statusFilter });
        }
    }

    @Action(CarsDeselectStatusFilter)
    deselectStatusFilter(
        { patchState, getState }: StateContext<CarsStateModel>,
        { status }: CarsDeselectStatusFilter
    ): void {
        const state = getState();
        const statusFilter = [ ...state.statusFilter ];

        const existedIndex = statusFilter.indexOf(status);

        if (existedIndex >= 0) {
            statusFilter.splice(existedIndex, 1);
            patchState({ statusFilter });
        }
    }

    @Action(CarsToggleStatusFilter)
    toggleStatusFilter(
        { patchState, getState }: StateContext<CarsStateModel>,
        { status }: CarsToggleStatusFilter
    ): void {
        const state = getState();
        const statusFilter = [ ...state.statusFilter ];

        const existedIndex = statusFilter.indexOf(status);

        if (existedIndex >= 0) {
            statusFilter.splice(existedIndex, 1);
        } else {
            statusFilter.push(status);
        }

        patchState({ statusFilter });
    }

    @Action(CarsClearStatusFilter)
    clearStatusFilter(
        { patchState, getState }: StateContext<CarsStateModel>
    ): void {
        const state = getState();

        if (state.statusFilter.length > 0) {
            patchState({ statusFilter: [] });
        }
    }

    @Action(CarsChangeShowByParks)
    changeShowByGroup(
        { patchState }: StateContext<CarsStateModel>,
        { showByParks }: CarsChangeShowByParks
    ): void {
        patchState({
            showByParks,
            parkRemoveAllowed: false,
        });
    }

    @Action(CarsSelectPark)
    selectPark(
        { patchState, getState }: StateContext<CarsStateModel>,
        { parkId }: CarsSelectPark
    ): void {
        const state = getState();

        const selectedParkIds = [ ...state.selectedParkIds ];

        if (!selectedParkIds.includes(parkId)) {
            selectedParkIds.push(parkId);
            patchState({ selectedParkIds });
        }
    }

    @Action(CarsDeselectPark)
    deselectPark(
        { patchState, getState }: StateContext<CarsStateModel>,
        { parkId }: CarsDeselectPark
    ): void {
        const state = getState();

        const selectedParkIds = [ ...state.selectedParkIds ];
        const index = selectedParkIds.indexOf(parkId);

        if (index >= 0) {
            selectedParkIds.splice(index, 1);
            patchState({ selectedParkIds });
        }
    }

    @Action(CarsToggleParkSelected)
    toggleParkSelected(
        { patchState, getState }: StateContext<CarsStateModel>,
        { parkId }: CarsDeselectPark
    ): void {
        const state = getState();

        const selectedParkIds = [ ...state.selectedParkIds ];
        const index = selectedParkIds.indexOf(parkId);

        if (index >= 0) {
            selectedParkIds.splice(index, 1);
        } else {
            selectedParkIds.push(parkId);
        }

        patchState({ selectedParkIds });
    }

    @Action(CarsSetParkRemoveAllowed)
    setParkRemoveAllowed(
        { patchState }: StateContext<CarsStateModel>,
        { parkRemoveAllowed }: CarsSetParkRemoveAllowed
    ): void {
        patchState({ parkRemoveAllowed });
    }

    @Action(CarsToggleParkRemoveAllowed)
    toggleParkRemoveAllowed(
        { patchState, getState }: StateContext<CarsStateModel>
    ): void {
        const state = getState();
        patchState({ parkRemoveAllowed: !state.parkRemoveAllowed });
    }

    @Action(CarsRemovePark)
    removePark(
        { dispatch }: StateContext<CarsStateModel>,
        { parkId }: CarsRemovePark
    ): Observable<DDDBaseResponseDto> {
        return this.dddQueryService.deleteEntity(parkId).pipe(
            tap(() => dispatch(new CarsGetVehicles(0)))
        );
    }
}
