import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { AuthState } from '../auth/store/auth.state';
import { APP_URL_MAP } from '../app-url-map.';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class RegistrationCompletedGuard implements CanActivate, CanLoad {
    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly authService: AuthService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.checkStatus();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkStatus();
    }

    checkStatus(): Observable<boolean> {
        return this.authService.profileLoaded$.pipe(
            filter(loaded => loaded),
            switchMap(() => this.store.select(AuthState.status)),
            map(status => {
                if (!!status && [ 'REGISTRATION', 'VERIFICATION' ].includes(status)) {
                    this.router.navigate([ '/', APP_URL_MAP.login.url, APP_URL_MAP.orgRegistration.url ]).then();
                    return false;
                }

                return true;
            })
        );
    }
}
